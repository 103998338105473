import footLogo from "../../assets/images/Agile_Logo.png";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import fb from "../../assets/images/Facebook.webp";
import insta from "../../assets/images/Instagram.webp";
import linkedIn from "../../assets/images/Linkedin.webp";
import tweet from "../../assets/images/Twitter.webp";
import sysmedac from "../../assets/images/sysmedac.webp";
import footImg from "../../assets/images/footer_illustration.webp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import isoLogo from "../../assets/images/iso-logo.webp";
import { useNavigate } from "react-router-dom";
import { paths } from "../../config/constant";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import MyPDF from '../../assets/images/file.pdf';
import { Dialog, DialogContent, TextField } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import { downloadBrochure, getCurrentNewsLetters } from "../../models/model";
import { LoadingButton } from '@mui/lab';

function Footer() {
  const Navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [_buttonLoading, _setbuttonLoading] = useState(false);
  const [_link, _setLink] = useState<any>([]);
  const [_formData, _setFormData] = useState<any>({
    name: "",
    email: "",
    MobileNumber: "",
    CompanyName: "",
  });

  const validateEmail = (email: string | null | undefined): boolean => {
    let isValid = true;

    if (!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      isValid = false;
    }
    return isValid;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const changeFormData = (name: string, value: any) => {
    _setFormData({ ..._formData, [name]: value });
  };

  const validate = {
    name: { error: false, message: "" },
    email: { error: false, message: "" },
    CompanyName: { error: false, message: "" },
    MobileNumber: { error: false, message: "" },
  };

  const [_brouchreValidate, _setBrouchreValidate] = useState(validate);

  const brochureValidate = () => {
    _setbuttonLoading(true)
    debugger;
    const _validate: any = Object.assign({}, validate);
    let valid = true;
    if (_formData.name === "" || _formData.name === 0) {
      _validate.name.error = true;
      _validate.name.message = "Required Field";
      valid = false;
    }
    if (_formData.email === "" || _formData.email === 0) {
      _validate.email.error = true;
      _validate.email.message = "Required Field";
      valid = false;
    }
    if (!validateEmail(_formData.email)) {
      _validate.email.error = true;
      _validate.email.message = "please enter valid Email";
      valid = false;
    }
    if (_formData.CompanyName === "" || _formData.CompanyName === 0) {
      _validate.CompanyName.error = true;
      _validate.CompanyName.message = "Required Field";
      valid = false;
    }
    if (_formData.MobileNumber === "" || _formData.MobileNumber === 0) {
      _validate.MobileNumber.error = true;
      _validate.MobileNumber.message = "Required Field";
      valid = false;
    }

    _setBrouchreValidate(_validate);
    if (!valid) {
      _setbuttonLoading(false);
      return;
    }

    downloadBrouchure()


  };


  const downloadBrouchure = () => {
    downloadBrochure(_formData).then((response: any) => {
      debugger;
      if (response.data.data.isSuccess === true) {
        Swal.fire({
          //title: 'Success!',
          text: 'Download Successful',
          icon: 'success',
          confirmButtonText: 'OK',
        })
          .then(() => {
            const downloadLink = document.createElement('a');
            //downloadLink.href = MyPDF; 
            downloadLink.href = _link;
            downloadLink.download = 'Brochure.pdf';
            downloadLink.target = '_blank';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          })
        _setbuttonLoading(false)
        _setFormData({
          ..._formData,
          name: "",
          email: "",
          MobileNumber: "",
          CompanyName: "",
        });
        handleClose();
      }
      else {
        handleClose()
        Swal.fire({
          text: 'Please try again later',
          confirmButtonText: 'OK',
        });
      }
    }).catch((error: any) => {
      handleClose()
      Swal.fire({
        icon: 'warning',
        //title: 'Warning!',
        text: 'Please try again later.',
        confirmButtonText: 'OK'
      });
      console.log(error)
    });
  };


  const getBrochure = () => {
    getCurrentNewsLetters()
      .then(response => {
        debugger;
        _setLink(response?.data?.data?.data[0]?.templateImageUrl);
        console.log(_link);
      })
      .catch((err) => console.log(err)).finally(() => _setbuttonLoading(false));
  }

  useEffect(() => {
    getBrochure();
  }, []);


  return (
    <>
      <div style={{ border: "2px solid #DB6F09" }}>
        <div className="footerArea">
          <div className="col-md-12">
            {/* <div className="footLogo d-flex justify-content-center align-items-center p-3">
              <img style={{ height: "4.5em" }} src={footLogo} alt="logo" />

              <Button className="servButton downloadButton" variant="contained">
                  DOWNLOAD BROCHURE &nbsp; <FileDownloadIcon />
                </Button>
            </div> */}

            <div className="footLogo d-flex justify-content-between align-items-center p-3">
              <img style={{ height: "5em" }} src={footLogo} alt="logo" />
              <Button className="servButton downloadButton" variant="contained"
                onClick={handleClickOpen}
              >
                DOWNLOAD BROCHURE &nbsp; <FileDownloadIcon />
              </Button>
            </div>

            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <div className="col-md-12 d-flex justify-content-between">
                  <span className="text-primary fs-4">Download Brochure</span>
                  <CancelIcon className="text-primary" style={{ cursor: "pointer" }} onClick={handleClose} />
                </div>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Name"
                  type="text"
                  className="py-2"
                  fullWidth
                  value={_formData.name}
                  onChange={(e: any) => changeFormData("name", e.target.value)}
                  error={_brouchreValidate?.name?.error}
                  helperText={_brouchreValidate?.name?.message}
                />
                <TextField
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  className="py-2"
                  value={_formData.email}
                  onChange={(e: any) => changeFormData("email", e.target.value)}
                  error={_brouchreValidate?.email?.error}
                  helperText={_brouchreValidate?.email?.message}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="Mobile Number"
                  type="text"
                  className="py-2"
                  fullWidth
                  value={_formData.MobileNumber}
                  // onChange={(e: any) => changeFormData("MobileNumber", e.target.value)}
                  // onChange={(e: any) =>
                  //   !isNaN(e.target.value)
                  //     ? changeFormData("MobileNumber", e.target.value)
                  //     : changeFormData("MobileNumber", "")
                  // }
                  onChange={(e: any) => {
                    const inputValue = e.target.value;
                    const numericValue = parseInt(inputValue);
                    if (!isNaN(numericValue) && inputValue.length <= 10) {
                      changeFormData("MobileNumber", numericValue.toString());
                    } else {
                      changeFormData("MobileNumber", "");
                    }
                  }}
                  error={_brouchreValidate?.MobileNumber?.error}
                  helperText={_brouchreValidate?.MobileNumber?.message}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="Company Name"
                  type="text"
                  className="py-2"
                  fullWidth
                  value={_formData.CompanyName}
                  onChange={(e: any) => changeFormData("CompanyName", e.target.value)}
                  error={_brouchreValidate?.CompanyName?.error}
                  helperText={_brouchreValidate?.CompanyName?.message}
                />
                <div className="d-flex justify-content-end py-2">
                  <LoadingButton
                    loading={_buttonLoading}
                    loadingPosition="end"
                    endIcon={<></>}
                    className="rounded border bg-primary text-white w-50" onClick={brochureValidate} variant="contained">
                    Download
                  </LoadingButton>
                </div>
              </DialogContent>
            </Dialog>


            <div className="col-md-12 d-flex flex-wrap px-3 footArea2">
              <div className="col-md-4 col-sm-12 bR  p-3 p-sm-0" style={{ lineHeight: "2em" }}>
                <div className="row">
                  <div className="col-md-1 col-sm-1 mob-Hide">
                    <ApartmentIcon className="apartmentIco" />
                  </div>
                  <div className="col-md-11 col-sm-12">
                    <label className="fs-18 pb-2 d-flex">
                      <ApartmentIcon className="desk-Hide apartmentIco" />Registered Office
                    </label>
                    <p className="fs-14 lh-24 mb-0">
                      NSIC - Software Technology Business Park Module-105
                      <br />
                      B-24, Guindy Industrial Estate, Ekkaduthangal
                      <br />
                      Chennai 600032, Tamil Nadu, India
                      <br />
                      CIN No.<b> U74999TN2017PTC116544</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12" >
                {/* <div className="row d-none">
                  <div className="col-md-1 col-sm-1 mob-Hide">
                    <ApartmentIcon className="apartmentIco" />
                  </div>
                  <div className="col-md-11 col-sm-11">
                    <label className="fs-18 pb-2 d-flex">
                      <ApartmentIcon className="desk-Hide apartmentIco" />Corporate Office
                    </label>
                    <p className="fs-14 lh-24 mb-0">
                      NSIC - Software Technology Business Park Module-105
                      <br />
                      B-24, Guindy Industrial Estate, Ekkaduthangal
                      <br />
                      Chennai 600032, Tamil Nadu, India
                    </p>
                  </div>
                </div> */}
              </div>


              {/* <div className="col-md-4 col-sm-12 p-3 bR getInTouch">
                <div className="row">
                  <div className="col-md-1 col-sm-1 mob-Hide">
                    <ApartmentIcon className="apartmentIco" />
                  </div>
                  <div className="col-md-11 col-sm-11">
                    <label className="fs-18 pb-2 d-flex">
                      <ApartmentIcon className="desk-Hide apartmentIco" />Overseas office
                    </label>
                    <p className="fs-14 lh-24 mb-0">
                      Agile Project Management Consultants -FZCO
                      <br />
                      Dubai Silicon Oasis, DDP, Building A1,
                      <br />
                      Dubai, United Arab Emirates
                      <br />
                      Mobile : +971 506023148
                      <br/>
                      Email : info@agilepmc.com/ pnk@agilepmc.com
                    </p>
                  </div>
                </div>
                <div className="row">
                  <label className="fs-18 pb-2">Get in Touch</label>
                  <p className="my-0">
                    <a
                      className="text-decoration-none fs-14"
                      href="tel:+919176353378"
                    >
                      <CallIcon className="fs-18 apartmentIco" /> +91 91 76 35 33 78
                    </a>
                  </p>
                  <p className="my-0">
                    <a
                      className="text-decoration-none fs-14"
                      href="mailto:info@agilepmc.com"
                    >
                      <EmailIcon className="fs-18 apartmentIco" /> info@agilepmc.com
                    </a>
                  </p>
                </div>
                <div className="col-md-10 justify-content-between align-items-center socialLink pt-1">
                  <a href="https://www.facebook.com">
                    <img className="socialImg" src={fb} alt="fb" />
                  </a>
                  <a href="https://www.twitter.com">
                    <img className="socialImg" src={tweet} alt="tweet" />
                  </a>
                  <a href="https://www.instagram.com">
                    <img className="socialImg" src={insta} alt="insta" />
                  </a>
                  <a href="https://in.linkedin.com/company/agilepmc-pvt-ltd">
                    <img className="socialImg" src={linkedIn} alt="linkedIn" />
                  </a>
                </div>
              </div> */}


              {/* getinTouch */}
              <div className="col-md-4 col-sm-12 bR  p-3 p-sm-0" style={{ lineHeight: "2em" }}>
                <label className="fs-18 pb-2">Get in Touch</label>
                <p className="my-0">
                  <a
                    className="text-decoration-none fs-14"
                    href="tel:+919176353378"
                  >
                    <CallIcon className="fs-18 apartmentIco" /> +91 9176353378
                  </a>
                </p>
                <p className="my-0">
                  <a
                    className="text-decoration-none fs-14"
                    href="mailto:info@agilepmc.com"
                  >
                    <EmailIcon className="fs-18 apartmentIco" /> info@agilepmc.com
                  </a>
                </p>
                <p>
                  <a href="https://in.linkedin.com/company/agilepmc-pvt-ltd" target="_blank">
                    <img className="socialImg" src={linkedIn} alt="linkedIn" />
                  </a>
                </p>
              </div>
              {/* <div className="col-md-10 align-items-center socialLink pt-1 my-2">
                      <a href="https://www.facebook.com">
                        <img className="socialImg" src={fb} alt="fb" />
                      </a>
                      <a href="https://www.twitter.com">
                        <img className="socialImg" src={tweet} alt="tweet" />
                      </a>
                      <a href="https://www.instagram.com">
                        <img className="socialImg" src={insta} alt="insta" />
                      </a>
                     <a href="https://www.linkedin.com/">
                        <img className="socialImg" src={linkedIn} alt="linkedIn" />
                      </a> 
                  </div> */}
            </div>

            {/* <div className="row d-flex justify-content-center p-lg-4 p-md-4 p-sm-0">
                <div className="col-md-12 row d-flex justify-content-between mx-auto py-2 bR"> */}
            {/* <div className="col-md-8 order-md-1 order-2 quickLinkDiv d-flex flex-column align-items-center my-auto bg-light-1">
                    <label className="fs-18 pb-3">Quick Links</label>
                    <div className="col-md-10 fs-14 d-flex flex-wrap quickLinks">
                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.home)}>Home</a>

                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.aboutus)}>About Us</a>

                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.servicesDesignEngg)}>Services</a>

                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.productsBalancePlantEquipment)}>Products & Spares</a>

                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.contactForAssociation)}>Contact Us</a>

                      <a className="pointer text-decoration-none py-2 px-4" onClick={() => Navigate(paths.associates)}>Associates</a>

                    </div>
                  </div> */}
            {/* <div className="col-md-3">
                      <label className="fs-18 pb-2">Get in Touch</label>
                      <p className="my-0">
                        <a
                          className="text-decoration-none fs-14"
                          href="tel:+919176353378"
                        >
                          <CallIcon className="fs-18 apartmentIco" /> +91 9176353378
                        </a>
                      </p>
                      <p className="my-0">
                        <a
                          className="text-decoration-none fs-14"
                          href="mailto:info@agilepmc.com"
                        >
                          <EmailIcon className="fs-18 apartmentIco" /> info@agilepmc.com
                        </a>
                      </p>
                    </div> */}
            {/* <div className="col-md-10 align-items-center socialLink pt-1 my-2">
                      <a href="https://www.facebook.com">
                        <img className="socialImg" src={fb} alt="fb" />
                      </a>
                      <a href="https://www.twitter.com">
                        <img className="socialImg" src={tweet} alt="tweet" />
                      </a>
                      <a href="https://www.instagram.com">
                        <img className="socialImg" src={insta} alt="insta" />
                      </a>
                      <a href="https://www.linkedin.com/">
                        <img className="socialImg" src={linkedIn} alt="linkedIn" />
                      </a>
                  </div> */}
            {/* </div>
            </div> */}



            <div className="col-md-12 d-flex justify-content-center" >
              <img src={footImg} className="w-100" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap p-4 poweredBy">
          <div className="d-flex align-items-center">
            {/* <img src={isoLogo} style={{ width: "3em", padding: "0px" }} alt="ISO-Certificate" /> */}
            <div className="fs-6 fw-bold ms-2">ISO 9001:2015 Certification</div>
          </div>
          <div className="foot-2 fs-6 fw-bold">
            AGILE PMC <strong className="fs-18 px-1"> &#169; </strong> {new Date().getFullYear()} All Rights Reserved | Powered by{" "}
            {/* <img
              style={{ width: "6em", padding: "0px" }}
              src={sysmedac}
              alt="sysmedac"
            /> */}
            <a href="https://sysmedac.com/#!/" target="_blank" rel="noopener noreferrer">
              <img
                style={{ width: "6em", padding: "0px" }}
                src={sysmedac}
                alt="sysmedac"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
